import React from "react";
import { faMotorcycle } from '@fortawesome/free-solid-svg-icons'
import IconButton from "../IconButton/IconButton"
import SectionHeader from "../SectionHeader/SectionHeader"
import theBike from "../../../static/images/thebike.svg"
import theRiders from "../../../static/images/theriders.svg"
import theHeats from "../../../static/images/theheats.svg"
import theTrack from "../../../static/images/thetrack.svg"
import "./aboutSpeedway.scss";

export default function aboutSpeedway(){

    return(
        <section className="aboutSpeedway">
            <div className="aboutSpeedway__inner">
                <SectionHeader  className="aboutSpeedway__title">
                    This, Is, <span className="aboutSpeedway__capitals">Speedway</span>
                </SectionHeader>
                <p>
                    World Championship Speedway is quite simply pure, adrenaline charged, high octane motorcycle racing.
                </p>
                <div className="aboutSpeedway__posterTrack">
                    <img className="aboutSpeedway__poster" src={theBike} width={200} alt="500CC single cylinder motorcycle" />
                    <img className="aboutSpeedway__poster" src={theRiders} width={200} alt="4 riders per heat"/>
                    <img className="aboutSpeedway__poster" src={theHeats} width={200} alt="23 heats, 60 seconds, 1 winner"/>
                    <img className="aboutSpeedway__poster" src={theTrack} width={200} alt="4 laps of the c350M track"/>
                </div>
                <p>
                    It’s dramatic, intense, wheel to wheel and action packed motorsport. You only need to see for yourself. 
                </p>
                <IconButton 
                    linkText="Learn more"
                    linkTarget="/about" 
                    imgIconFa={faMotorcycle} ></IconButton>
            </div>
        </section>
    )
}
