import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import IconButton from "../IconButton/IconButton"
import "./biography.scss"

const Biography = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/bio2.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    `)

    return (
        <BackgroundImage
        Tag="section"
        className={"Biography"}
        fluid={data.file.childImageSharp.fluid}
        title="Max's Biography">
          <div className="Biography__inner">
            <h2 className="Biography__title">Max Fricke</h2>
            <p className="Biography__text">Some people are made for speedway. It’s in their DNA. They want to start early, probably before they can walk or even crawl. Max Frinke is one of those people. As the 2016 U21 Speedway World Champion, 4 Time Australian Under 21 Speedway Champion (2013, 2014, 2015 & 2017) and two time Australian Senior Solo Speedway Champion (2019 & 2020), few will argue with his accomplishments. Having travelled the world, he’s set on leaving a legacy in the sport he loves.</p>
            <IconButton 
              className="Biography__button"
              linkText="Learn More"
              linkTarget="/about"
              imgIconFa={faTrophy}
              ></IconButton>
          </div>
        </BackgroundImage>
    )
}

export default Biography