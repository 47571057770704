import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import IconButton from "../IconButton/IconButton"
import "./latestNews.scss"

const LatestNews = () => {
    
    const data = useStaticQuery(graphql`
    query LatestNews {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "News"}}},
            sort: {fields: frontmatter___date},
            limit: 1) {
            nodes {
                frontmatter {
                    path
                    title
                    date(formatString: "dddd LL")
                    featured {
                        publicURL
                    }
                }
                excerpt
            }
        }
      }`).allMarkdownRemark.nodes[0]

      const frontmatter = data.frontmatter;
    return(
        <section title="Next Event" className="latestNews">
            <div className="latestNews__inner">
                <em className="latestNews__heading">Latest News</em>
                <h2 className="latestNews__title">
                    {frontmatter.title}
                </h2>
                <p>
                    {data.excerpt}
                </p>

                <IconButton
                    linkText="Learn More"
                    linkTarget={frontmatter.path}
                    imgIconFa={faNewspaper}
                />
            </div>
        </section>
    )
}

export default LatestNews;