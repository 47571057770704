import React from "react";
import Layout from "../layout";
import NextEvent from "../components/NextEvent/NextEvent";
import LatestNews from "../components/LatestNews/LatestNews";
import Biography from "../components/Biography/Biography";
import AboutSpeedway from "../components/AboutSpeedway/AboutSpeedway";
import SectionHeader from "../components/SectionHeader/SectionHeader";


const IndexPage = () => {
    return (
        <Layout>
            <div className="whatsOnHeading">
                <SectionHeader>What's On</SectionHeader>
            </div>
            <NextEvent></NextEvent>
            <Biography></Biography>
            <AboutSpeedway></AboutSpeedway>
            <LatestNews></LatestNews>
        </Layout>
    );
}

export default IndexPage;
